export default {
    async mounted() {
        this.user = this.$auth.user();
    },
    data() {
        return {
            user: null
        };
    },

    computed: {
        hasPermission() {
            if (this.user) {
                if (this.registry && this.registry.category) {
                    return (
                        this.user.permissions.includes("Admin") ||
                        this.user.permissions.includes(
                            this.registry.category.name
                        )
                    );
                }
                return this.user.permissions.includes("Admin");
            }
            return false;
        }
    }
};
